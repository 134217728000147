import React from 'react'
import billingIcon from '../images/SVG/billingIcon.svg'
import TokenTab from './TokenTab'
import { DialogWrapper } from '@/components/ui/CardWrapper'

const AddToken = ({ setIsOpen }) => {
  return (
    <DialogWrapper
      title="Add Tokens"
      description="Specify the number of tokens you want to purchase or send a request for invoice."
      icon={billingIcon}
      setIsOpen={setIsOpen}
    >
      <TokenTab setIsOpen={setIsOpen} />
    </DialogWrapper>
  )
}

export default AddToken