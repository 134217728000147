import React from "react";
import {
  Card,
  CardContent,
} from "@/components/shadcn-ui/card";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { Link } from "react-router-dom";
import CustomWeglotSwitcher from "../CustomWeglotSwitcher";
import { ProfileWrapper } from "../ui/CardWrapper";

const General = () => {
  const email = localStorage.getItem('email')
  return (
    <ProfileWrapper
      title='General'
      description='Manage your profile settings'
      hasButton={false}
    >
      <CardContent className="px-0 relative">
        <Card className="rounded-2xl h-[180px]">
          <CardContent className="p-5 h-full">
            <form action="" className="flex flex-col flex-wrap gap-4">
              <div className="flex flex-col gap-[3px]">
                <div className="flex justify-between items-center">
                  <Label className="font-SuisseIntlLight font-semibold">
                    Email
                  </Label>
                  <Link to='mailto:hey@mpassist.ai' className="font-SuisseIntlLight font-semibold text-[14px] text-[#71717A] underline">
                    Change
                  </Link>
                </div>
                <Input
                  className="font-semibold placeholder:font-SuisseIntlLight placeholder:font-semibold"
                  placeholder="george@mail.com"
                  value={email}
                  disabled
                  type="email"
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label className="font-semibold font-SuisseIntlLight">
                  Language
                </Label>
                <CustomWeglotSwitcher />
              </div>
            </form>
          </CardContent>
        </Card>
      </CardContent>
    </ProfileWrapper>
  );
};

export default General;
