import { createSlice } from "@reduxjs/toolkit"
import { FetchUser } from "./UserApis"

const initialState = {
  userData: null,
  tokenCount: null,
  service_name: null,
  paying_customer: null,
  trial_end_date: null,
  templates: [], 
  loading: false,
  failed: false
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setTokenCount: (state, action) => {
      state.tokenCount = action.payload
    },
    setServiceName: (state, action) => {
      state.service_name = action.payload
    },
    setPayingCustomer: (state, action) => {
      state.paying_customer = action.payload
    },
    setTrialEndDate: (state, action) => {
      state.trial_end_date = action.payload
    },
    setTemplates: (state, action) => {
      state.templates = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
      state.tokenCount = action.payload?.tokens
    }
  },
  extraReducers: (builder) => {
    builder.addCase(FetchUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(FetchUser.fulfilled, (state, action) => {
      const { user, toolId } = action.payload

      state.userData = user
      state.tokenCount = user?.tokens
      state.paying_customer = user?.paying_customer
      state.trial_end_date = user?.trial_end_date
      state.service_name = user?.service_name
      state.templates = toolId ? user.templates?.filter((template) => template.tool_id === toolId).map((template) => template.name) : user?.templates
      state.loading = false
    })
    builder.addCase(FetchUser.rejected, (state) => {
      state.loading = false
      state.failed = true
    })
  }
})

export const { setTokenCount, setServiceName, setPayingCustomer, setTrialEndDate, setTemplates, setUserData } = userSlice.actions

export const getTokenCount = (state) => state.userSlice.tokenCount
export const getServiceName = (state) => state.userSlice.service_name
export const getPayingCustomer = (state) => state.userSlice.paying_customer
export const getTrialEndDate = (state) => state.userSlice.trial_end_date
export const getTemplates = (state) => state.userSlice.templates
export const selectUserData = (state) => state.userSlice.userData
export const selectLoading = (state) => state.userSlice.loading
export const selectFailed = (state) => state.userSlice.failed

export default userSlice.reducer
