export default function userPermissions(user) {
    const user_permissions = {
        'default': [
            "general",
            "vocabulary",
            "text-modules"
        ],
        'admin': [
            "general",
            "members",
            "billing",
            "vocabulary",
            "text-modules",
            "plans"
        ],
        'assistant': [
            "general",
            "vocabulary",
            "text-modules"
        ],
        'doctor': [
            "general",
            "vocabulary",
            "text-modules"
        ]
    }

    if (!user) {
        return user_permissions['default']
    }

    if (!!user.is_admin) {
        return user_permissions['admin']
    }

    return user_permissions[user.role]
}