import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextInput from './ui/TextInput';
import DropDown from './ui/DropDown';
import { Button } from './shadcn-ui/button';
import { selectAllTemplates } from '../redux/ReportsSlice';
import { getTemplates as getUserTemplates } from '../redux/UserSlice';
import { toast } from 'react-toastify';
import RecordingPopUp from './RecordingPopUp';
import { useCurrentTool } from '@/utils/ToolHelpers';
import { Checkbox } from './shadcn-ui/checkbox';
import { checkIfOnline } from '@/utils/HelperFunctions';
import Loader from './Loader';

export default function RecordForm({ GenerateReport, recordedAudioBlob, handleGenerateReport, customTemplates, language, allModelsLanguageList, UploadNewRecording, HandleResetState }) {   
    const templates = useSelector(selectAllTemplates);
    const userTemplates = useSelector(getUserTemplates);
    const [loading, setLoading] = useState(false);
    const { currentToolFields, generation_name } = useCurrentTool();

    const toolFields = currentToolFields.reduce((acc, field) => {
      acc[field.name] = localStorage.getItem(field.name) || '';
      return acc;
    }, {});

    const [toolFieldState, setToolFieldState] = useState(toolFields);

    const [formState, setFormState] = useState({
        audioLanguage: localStorage.getItem('audio_language') || 'de',
        reportLanguage: localStorage.getItem('report_language') || 'german (switzerland)',
        comment: '',
        template: 'select_template',
        urgent: false,
    });

    const [uploadNew, setUploadNew] = useState(false);

    useEffect(() => {
        setFormState((prev) => ({ ...prev, recordedAudioBlob }));
    }, [recordedAudioBlob]);

    const handleToolFieldChange = (e) => {
        const { name, value } = e.target;
        setToolFieldState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
      
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };
    
    const handleDropDownChange = (value) => {
        setFormState((prev)=>({...prev, [value.name]:value.value}))
    }

    const handleGenerateNewReport = async (e) => {
        e.preventDefault();

        setLoading(true);
        const isOnline = await checkIfOnline();
        setLoading(false);
        if(!isOnline){
            toast.error('Network error. Please check your internet connection and try again.');
            return;
        }
      
        const missingToolFields = currentToolFields.filter(
          (field) => !toolFieldState[field.name]
        ).length;
      
        const { audioLanguage, reportLanguage, template, urgent } = formState;
      
        if (missingToolFields > 0 || !audioLanguage || !reportLanguage || !recordedAudioBlob) {
          toast.error("Please fill in all fields before sending.");
          return;
        }
      
        if ((!template || template === 'select_template') && getTemplates().length) {
            toast.error("Please select a template.");
            return;
        }
      
        const isCustomTemplate = userTemplates.includes(template);
        const toolFieldsData = currentToolFields.map((field) => ({
            id: field.id || '',
            name: field.name,
            value: toolFieldState[field.name],
        }));
      
        const data = {
          toolFieldsData,
          ...formState,
          uses_custom_template: isCustomTemplate,
          recordedAudioBlob,
          urgent,
        };

        try {
            GenerateReport('generating');
            await handleGenerateReport(data);
        } catch (error) {
            console.error("Error generating report: ", error);
            toast.error(`The ${generation_name.toLowerCase()} could not be created. Please try again.`);
        }
    };

    const getTemplates = () => {
        const systemTemplates = [...templates].sort((a, b) => {
            if (a.name === "no template") return -1;
            if (b.name === "no template") return 1;
            return a.name.localeCompare(b.name);
          });
        const additionalUserTemplates = userTemplates?.length ? userTemplates.map(template => ({ name: template, value: template }))?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        additionalUserTemplates.map(template => template.name = `Custom - ${template.name}`);
        return [...systemTemplates, ...additionalUserTemplates];
    };

    const inputLanguages = allModelsLanguageList.filter(item => item.name !== "German (Switzerland)");
    const outputLanguages = language.map(item => ({ name: item.name, value: item.name.toLowerCase() }));
    const templateOptions = getTemplates();
    return (
        <div className='w-full'>
            <form className='text-left w-full' onSubmit={handleGenerateNewReport}>
                <div className='mt-[10px] grid grid-cols-2 md:gap-x-[20px] gap-3'>
                {currentToolFields.map((field) => (
                    <TextInput
                        key={field.name}
                        required
                        name={field.name}
                        label={field.name.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
                        placeholder={`Enter ${field.name.replace(/_/g, ' ')}`}
                        type={field.type || 'text'}
                        value={toolFieldState[field.name]}
                        className={`${field.position === 3 || currentToolFields.length === 1 ? 'col-span-2' : 'col-span-2 md:col-span-1'}`}
                        onChange={handleToolFieldChange}
                    />
                ))}
                    
                </div>
                <div className='mt-[10px] flex flex-col sm:flex-row gap-[20px]'>
                    <div className='flex-1'>
                        <DropDown
                            label='Input language'
                            options={inputLanguages}
                            handleOnChange={(value) => { handleDropDownChange({ name: 'audioLanguage', value: value }) }}
                            selectedValue={formState.audioLanguage}
                            defaultValue='select_language'
                        />
                    </div>
                    <div className='flex-1'>
                        <DropDown
                            label='Output language'
                            options={outputLanguages}
                            handleOnChange={(value) => { handleDropDownChange({ name: 'reportLanguage', value: value }) }}
                            selectedValue={formState.reportLanguage}
                            defaultValue='select_language'
                        />
                    </div>
                </div>
                {templateOptions.length > 0 && <div className='mt-[10px]'>
                    <DropDown
                        label='Template'
                        options={templateOptions}
                        handleOnChange={(value) => { handleDropDownChange({ name: 'template', value: value }) }}
                        selectedValue={formState.template === 'select_template' ? null : formState.template}
                        defaultValue='select_template'
                    />
                </div>}
                <div className='mt-[10px] flex flex-col text-sm gap-1 w-full'>
                    <label htmlFor="comment" className="font-semibold font-SuisseIntlLight text-[14px]">Comment</label>
                    <textarea
                        id="comment"
                        name="comment"
                        className="rounded-[10px] inputFieldShadow text-[14px] font-SuisseIntlLight font-normal text-[#868C98] border-[1px] py-2.5 px-4 border-[#E2E4E9] hover:border-[#CED0D5] focus:border-[#A6A8AD] focus:ring-0 focus:outline-none w-full resize-none"
                        rows="3"
                        maxLength='5000'
                        placeholder='Add a comment (optional)'
                        value={formState.comment}
                        onChange={handleChange}
                    />
                </div>
                <div className="mt-[10px] flex text-sm gap-2 items-center">
                    <Checkbox 
                        id="urgent" 
                        className={`border-[2.3px] border-slate-200 rounded-[4px] ${formState.urgent ? 'border-0' : 'bg-white'}`}
                        checked={formState.urgent}
                        onCheckedChange={(checked) => setFormState((prev) => ({ ...prev, urgent: checked }))}
                    />
                    <label htmlFor="urgent" className="font-medium text-[14px] flex flex-col cursor-pointer">
                        <span className="font-semibold font-SuisseIntlLight">Mark as Urgent</span>
                        <p className='font-normal text-[#868C98] -mt-[2px]'>The {generation_name.toLowerCase()} will be highlighted in the list view.</p>
                    </label>
                </div>
                <div className={`sm:flex mt-5`}>
                    <Button type='submit' disabled={loading} className='w-full'>
                        {loading ? 
                            <Loader/>
                        :
                            <span>Generate {generation_name.toLowerCase()}</span>

                        }
                    </Button>
                </div>
            </form>
            {uploadNew && (
                <RecordingPopUp onUploadNewRecording={UploadNewRecording} setUploadNew={setUploadNew} HandleReset={HandleResetState} />
            )}
        </div>
    );
}
