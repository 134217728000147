import React, { useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn-ui/select"

function CustomWeglotSwitcher() {
  const [currentLanguage, setCurrentLanguage] = useState('')

  const handleLanguageChange = (newLanguage) => {
    // eslint-disable-next-line no-undef 
    Weglot.switchTo(newLanguage);
    setCurrentLanguage(newLanguage)
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef 
    setCurrentLanguage(Weglot.getCurrentLang())
  }, [])

  useEffect(() => {
    if (currentLanguage) {
      handleLanguageChange(currentLanguage)
    }
  }, [currentLanguage])

  return (
    <Select
      id="weglot-switch"
      value={currentLanguage}
      onValueChange={setCurrentLanguage}
    >
      <SelectTrigger className="">
        <SelectValue placeholder="Select language" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="en">English</SelectItem>
        <SelectItem value="es">Español</SelectItem>
        <SelectItem value="de">Deutsch</SelectItem>
        <SelectItem value="fr">Français</SelectItem>
      </SelectContent>
    </Select>
  )
}

export default CustomWeglotSwitcher
