import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn-ui/tabs";
import { Button } from "@/components/shadcn-ui/button";
import memberIcon from "../images/SVG/member.svg";
import GeneralIcon from "../images/SVG/generalcon.svg";
import billingIcon from "../images/SVG/billingIcon.svg";
import planIcon from "../images/SVG/planIcon.svg";
import { handleLogout } from '@/utils/authSession'
import { General, Members, Billing, Vocabulary, TextModules, Plans } from "@/components/Profile/index.js"
import { useDispatch, useSelector } from "react-redux";
import { FetchMembers, FetchPlans, FetchTransactions, FetchSubstitutes } from "@/redux/ProfileApis";
import { selectActiveTab, setActiveTab } from "@/redux/ProfileSlice";
import VocabularyIcon from '../images/SVG/vocabulary.svg'
import TextModuleIcon from '../images/SVG/text-modules.svg'
import { selectFailed, selectLoading, selectUserData } from "@/redux/UserSlice";
import userPermissions from "@/lib/usersPermissions";
import Loader from "@/components/Loader";
import { FetchUser } from "@/redux/UserApis";

const tabTrigger = [
  {
    title: "General",
    icon: GeneralIcon,
    value: "general",
    component: General,
  },
  {
    title: "Members",
    icon: memberIcon,
    value: "members",
    component: Members,
  },
  {
    id: 3,
    title: "Billing",
    icon: billingIcon,
    value: "billing",
    component: Billing,
  },
  {
    title: "Vocabulary",
    icon: VocabularyIcon,
    value: "vocabulary",
    component: Vocabulary,
  },
  {
    title: "Text Modules",
    icon: TextModuleIcon,
    value: "text-modules",
    component: TextModules,
  },
  {
    title: "Plans",
    icon: planIcon,
    value: "plans",
    component: Plans,
  },
];

const Profile = () => {
  const activeTab = useSelector(selectActiveTab);
  const dispatch = useDispatch();

  const userId = localStorage.getItem("user_id");
  const user = useSelector(selectUserData);
  const userLoading = useSelector(selectLoading);
  const userFailed = useSelector(selectFailed);

  const currentUserPermissions = userPermissions(user);

  useEffect(() => {
    if (!!userId && userFailed) {
      handleLogout();
    }
  }, [userFailed, userId])
  
  useEffect(() => {
    if (userId && (!user || user.length === 0)) {
      dispatch(FetchUser({ userId }))
    }
  }, [dispatch, userId, user]);

  useEffect(() => {
    Promise.all([
      dispatch(FetchMembers()), 
      dispatch(FetchPlans()),
      dispatch(FetchTransactions()),
      dispatch(FetchSubstitutes()),
    ]);
  }, [dispatch]);

  if (userLoading) {
    return <div className="flex justify-center items-center h-[85vh] md:h-[630px] 2xl:h-[848px]">
      <Loader/>
    </div>
  }

  return (
    <div className="mt-4 p-0 md:h-[630px] 2xl:h-[848px] md:p-6 mb-5 w-[90vw] rounded-3xl md:w-[80vw] md:bg-white md:border md:border-[#E4E4E7] md:shadow-[#0000000A]">
      <Card className="border-none shadow-none w-full h-full bg-transparent">
        <CardHeader className="p-0 mb-3 md:hidden">
          <CardTitle className="font-bold">Settings</CardTitle>
        </CardHeader>
        <Tabs
          value={activeTab}
          onValueChange={(value) => dispatch(setActiveTab(value))}
        >
          <div className="grid grid-rows-5 md:grid-cols-12 gap-5 2xl:gap-8 md:h-full w-full sticky">
            <div className="row-span-5 md:col-span-4 lg:col-span-3">
              <Card className="relative rounded-2xl">
                <TabsList
                  data-orientation="vertical"
                  className="flex flex-col p-[10px] gap-2 overflow-y-scroll md:overflow-hidden h-full justify-start bg-transparent dark:bg-transparent"
                >
                  {tabTrigger.map((tab, index) => {
                    if (currentUserPermissions?.includes(tab.value)) {
                      return (
                        <TabsTrigger
                          key={index}
                          className="w-full justify-start data-[state=active]:bg-[#F6F8FA]"
                          value={tab.value}
                          onClick={() => dispatch(setActiveTab(tab.value))}
                        >
                          <img src={tab.icon} className="mr-3 w-5 h-5" alt={tab.title} />
                          <span className="font-SuisseIntlLight font-semibold">{tab.title}</span>
                        </TabsTrigger>
                      );
                    }
                    return null;
                  })}
                </TabsList>
              </Card>
              <Button
                className="mt-4 w-full relative hidden md:inline-block"
                variant="outline"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
            <div className="row-span-5 md:col-span-8 lg:col-span-9 md:max-w-[650px] 2xl:max-w-[720px] md:h-[575px] 2xl:h-[798px] overflow-auto no-scrollbar">
              {tabTrigger.map((tab) => (
                <TabsContent key={tab.value} value={tab.value}>
                  <tab.component />
                </TabsContent>
              ))}
            </div>
          </div>
        </Tabs>
      </Card>
    </div>
  );
};

export default Profile;