import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './shadcn-ui/table';
import { DisabledRetryIcon } from "./ui/SvgIcons";
import Loader from './Loader';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/shadcn-ui/tooltip"
import retryIcon from '../images/SVG/retryIcon.svg'
import commentIcon from "@/images/SVG/reportTableIcons/comment.svg";


const CustomTable = ({ selectedTabReports, columns, onClickFunction, handleRetry }) => {
  const [processingRows, setProcessingRows] = useState({});

  const getItemState = ({ status, id, error, report_fetched, comment }) => {
    const state = (status === 'completed' && !report_fetched) ? 'failed' : status;
    const isRowLoading = processingRows[id];

    if (state === 'retry' || isRowLoading) {
      return (
        <div className="text-[14px] font-SuisseIntlLight font-normal flex items-center gap-1">
          <Loader />
        </div>
      )
    }
    if (state === 'uploading' || (state === 'failed' && error === "Audio file missing")) {
      return (
        <>
          <Tooltip id="audio-not-available" place="top" />
          <div className="gap-1" data-tooltip-id="audio-not-available" data-tooltip-content="Audio not available">
            <button disabled className="bg-[#FAFAFA] rounded-full px-2.5 py-2.5 cursor-not-allowed">
              <DisabledRetryIcon />
            </button>
          </div>
        </>
      )
    }
    if (state === 'failed') {
      return (
        <div>
          <button className="block" 
            onClick={() => {
              setProcessingRows((prev) => ({ ...prev, [id]: true })); 
              handleRetry({ id });
              setTimeout(() => setProcessingRows((prev) => ({ ...prev, [id]: false })), 2000);
            }}
          >
            <img src={retryIcon} alt="retry icon" className='max-w-none w-4 h-4' />
          </button>
        </div>
      )
    }
    if (state === 'uploading') {
      return (
        <div className="gap-1" data-tooltip-id="audio-not-available" data-tooltip-content="Audio not available">
          <button disabled className="bg-[#FAFAFA] hidden sm:block rounded-full px-2.5 py-2.5">
            <DisabledRetryIcon />
          </button>
          <p className="text-[14px] flex items-center sm:hidden font-SuisseIntlLight font-normal"> Press here to retry</p>
        </div>
      )
    }
    if (!!comment) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger><img src={commentIcon} alt="comment icon" className='max-w-none w-4 h-4' /></TooltipTrigger>
            <TooltipContent side="left" align='end' className='flex flex-wrap max-w-[300px] p-2'>
              <p>{comment}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }
  }

  return (
    <div className='w-full border rounded-[20px] overflow-hidden'>
      <Table className='table-fixed'>
        <TableHeader>
          <TableRow>
            {columns.map((head, index) => (
              <TableHead
                className="capitalize text-[#71717A] font-SuisseIntlLight font-semibold lg:px-4 px-6 w-[220px] min-w-[220px]"
                key={index}
              >
                {head.Header}
              </TableHead>
            ))}
            <TableHead className="capitalize text-[#71717A] font-SuisseIntlLight font-semibold lg:px-4 px-6 w-[60px] min-w-[60px]">
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {selectedTabReports.map((report, index) => {
            const isDisabled = 
              (report.status === 'completed' && !report.report_fetched) || 
              (report.error === 'Audio file missing');

            const translate = ['status', 'template']

            return (
              <TableRow
                key={index}
                className={`relative ${(isDisabled || (report.status === 'processing')) && 'cursor-not-allowed'}`}
              >
                {columns.map((column) => (
                  <TableCell key={column.accessor} className={`p-0 w-[220px] min-w-[220px] ${translate.includes(column.accessor) ? '' : "wg-ignore"}`}>
                    <div
                      className={`w-full h-full lg:px-4 px-6 p-4 text-left ${(isDisabled || (report.status === 'processing')) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                      onClick={() => !(isDisabled || (report.status === 'processing')) && onClickFunction(report.row_number)}
                      disabled={isDisabled || (report.status === 'processing')}
                    >
                      {/* 1. If the column has value in report.fields object, then display the value.
                          2. If the column has a Cell function, check if its status or not.
                          3. If status is 'completed' and report_fetched is false, then pass the value to Cell function as 'failed'.
                          4. If not, then pass the value to the Cell function.
                          5. If none of the above, then display the value from report object.
                      */}
                      {report.fields?.[column.accessor] !== undefined ? (
                        report.fields[column.accessor]
                      ) : (
                        column.Cell
                          ? column.accessor === 'status' 
                          ? column.Cell({ value: isDisabled ? 'failed' : report[column.accessor] })
                          : column.Cell({ value: report[column.accessor] })
                          : report[column.accessor]
                      )}
                    </div>
                  </TableCell>
                ))}
                <TableCell className="w-10">
                  {getItemState(report)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default CustomTable;
