import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import { toast } from 'react-toastify';

let navigate, store;
export const setNavigate = (nav, str) => {
    navigate = nav;
    store = str;
};

export const handleLogout = async () => {
    try {
      await signOut();
      localStorage.removeItem('user_id');
      store.dispatch({ type: 'LOGOUT' });
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

export const fetchAuthSessionWithLogout = async () => {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    if (!accessToken) {
        const sessionExpired = localStorage.getItem('session_expired');
        if (sessionExpired && sessionExpired !== 'true') {
            toast.error('Your session has expired. Please sign in again.');
            localStorage.setItem('session_expired', 'true');
            await handleLogout();
        }
        return;
    }
    return accessToken;
};
