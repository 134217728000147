import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/shadcn-ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/shadcn-ui/table";
import { Button } from "@/components/shadcn-ui/button";
import AddToken from "@/components/AddToken";
import { upperCase } from "@/utils/constants";
import { useSelector } from "react-redux";
import { selectTransactions } from "@/redux/ProfileSlice";
import { formatTimestamp } from "@/utils/date";
import { getTokenCount } from "@/redux/UserSlice";
import { ProfileWrapper } from "../ui/CardWrapper";

const Billing = () => {
  const transactions = useSelector(selectTransactions) || [];
  const orgTokens = useSelector(getTokenCount);
  const [addTokenOpen, setAddTokenOpen] = useState(false);

  const getTokens = (transaction) => {
    const tokens = transaction.tokens?.toLocaleString('en') || 0;
    if (!tokens) return <span className="text-xs">Pending</span>;
    return transaction.type === "invoice" ? `+${tokens} Tokens` : `-${tokens} Tokens`;
  }

  return (
    <ProfileWrapper
      title='Billing'
      description='Manage your billing history and token usage'
      hasButton={false}
    >
      <CardContent className="p-0 w-full">
        <Card className="flex px-4 py-3 justify-between items-center rounded-2xl h-[76px] shadow-none">
          <div>
            <CardDescription className="text-[14px] font-semibold text-[#71717a]">
              Token Balance
            </CardDescription>
            <CardTitle className="mt-1 text-xl tracking-wider">{orgTokens?.toLocaleString('en')}</CardTitle>
          </div>
          <Dialog open={addTokenOpen} onOpenChange={setAddTokenOpen}>
            <DialogTrigger>
              <Button className="font-medium">Add Tokens</Button>
            </DialogTrigger>
            <DialogContent className="rounded-3xl">
              <AddToken setIsOpen={setAddTokenOpen} />
            </DialogContent>
          </Dialog>
        </Card>
        <div>
          <p className="text-black w-fit md:w-full font-SuisseIntlLight font-semibold text-base my-3 mt-5">
            Transaction History
          </p>
          <Card className="rounded-2xl w-full">
            {transactions?.length > 0 ? (
            <Table className="w-full rounded-[20px]">
              <TableBody>
                {transactions?.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell className="text-[12px] text-[#09090B] font-semibold">
                      <div className="md:hidden">
                        <p className="text-[#71717A] font-SuisseIntlLight font-medium text-xs">
                          {upperCase(transaction.type)}
                        </p>
                        <h1 className="font-SuisseIntlLight font-semibold text-base">
                          {formatTimestamp(transaction.created_at, 'd/m/y/h')}
                        </h1>
                      </div>
                      <div className="hidden md:block">
                        {formatTimestamp(transaction.created_at, 'd/m/y/h')}
                      </div>
                    </TableCell>
                    <TableCell className="hidden md:table-cell text-[12px] text-[#09090B] font-semibold">
                      {upperCase(transaction.type)}
                    </TableCell>
                    <TableCell className="text-right text-[#09090B] font-semibold">
                      <span className="hidden md:inline text-[12px] tracking-wider">
                        {getTokens(transaction)}
                      </span>
                      <span className="md:hidden text-base tracking-wider">
                        {getTokens(transaction)}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            ) : (
              <div className="flex justify-center items-center w-full h-40">
                <p className={`p-4 text-center text-sm md:text-xl font-medium text-[#71717A] flex items-center justify-center`}>
                  No invoices issued yet.
                </p>
              </div>
            )}
          </Card>
        </div>
      </CardContent>
    </ProfileWrapper>
  );
};

export default Billing;