import { useRef } from "react";
import { Input } from "../shadcn-ui/input";

const DateInput = ({ name, value, onChange, placeholder }) => {
  const inputRef = useRef(null);

  return (
    <div
      className="relative w-full cursor-pointer"
      onClick={() => inputRef.current?.showPicker()}
    >
      {!value && (
        <span className="absolute text-sm text-gray-500 font-SuisseIntlLight flex justify-center items-center left-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
          {placeholder}
        </span>
      )}
      <Input
        ref={inputRef}
        name={name}
        type="date"
        className={`"relative z-10 bg-transparent appearance-none flex justify-center items-center ${value ? '' : '[&::-webkit-datetime-edit]:opacity-0'} focus:outline-none"`}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export { DateInput };