import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAuthSessionWithLogout } from '../utils/authSession';
import { toast } from 'react-toastify';

export const FetchUser = createAsyncThunk('fetchUser', async ({ userId, toolId }, { rejectWithValue }) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/get-user/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({ user_id: userId })
        })
        if (!response.ok) {
            return rejectWithValue('Failed to fetch user');
        }
        const data = await response.json();
      
        if (data.error) {
          return rejectWithValue(data.error);
        }
        const user = data.user || null;
        return { user, toolId };
    } catch (error) {
        toast.error("Failed to fetch user details. Please try again.");
        return rejectWithValue(error.message);
    }
});