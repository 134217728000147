import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddUserIcon from "../images/SVG/addUser.svg";
import {
  Card,
  CardFooter,
} from "@/components/shadcn-ui/card";
import { Button } from "@/components/shadcn-ui/button";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { createUser } from "@/utils/HelperFunctions";
import { getCurrentUser, signIn, confirmSignIn } from 'aws-amplify/auth';
import PasswordChecklist from "react-password-checklist"
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { AuthWrapper } from "@/components/ui/CardWrapper";

const SignUp = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    inviteId: "",
    orgName: "",
  });

  const navigate = useNavigate();
  const [activeField, setActiveField] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPassValid, setisPassValid] = useState(false)
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if ((name === "inviteId" || name === "orgName") && value.trim() === "") {
      setActiveField(null);
    } else if (name === "inviteId" || name === "orgName") {
      setActiveField(name);
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSignUp = async () => {
    try {
      setLoading(true);
      const { email, password } = formData;

      const response = await createUser(formData);
      if (response) {
        const { nextStep } = await signIn({ username: email, password: password });
        if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          await confirmSignIn({ challengeResponse: formData.password });
        }

        const { userId } = await getCurrentUser();
        localStorage.setItem("user_id", userId)
        localStorage.setItem("email", email)
        localStorage.removeItem('session_expired');
        navigate("/")
      }
    } catch (error) {
      console.error("Error signing up:", error.message);
    } finally {
      setLoading(false);
    }
  }

  const disabled = loading || !isPassValid || !formData.name || !formData.email || !validateEmail(formData.email) || (formData.inviteId === "" && formData.orgName === "");

  return (
    <AuthWrapper heading='Create a new account' icon={AddUserIcon}>
      <form>
        <div className="grid w-full items-center gap-4 text-left mt-5">
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="name" className="font-semibold">Name</Label>
            <Input
              required
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              autoComplete="off"
              className="text-[#868C98] font-normal "
            />
          </div>
          <div className="flex justify-between gap-3 items-center">
            <div className="flex flex-col space-y-1.5 w-full">
              <Label htmlFor="name" className="font-semibold">Organisation</Label>
              <Input
                id="orgName"
                name="orgName"
                value={formData.orgName}
                onChange={handleChange}
                placeholder="Enter organisation name"
                className="text-[#868C98]  font-normal"
                disabled={activeField === "inviteId"}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="email" className="font-semibold">Email</Label>
            <Input
              required
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
              placeholder="Enter your email"
              autoComplete="new-email"
              className="text-[#868C98] font-normal "
            />
          </div>
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="password" className="font-semibold">Password</Label>
            <div className='relative'>
              <div className="relative">
                <Input
                  required
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  placeholder="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  className="text-[#868C98] font-normal "
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-3 flex items-center focus:outline-none"
                >
                  {showPassword ? <EyeOffIcon size={15} strokeWidth={2.5}/> : <EyeIcon size={15} strokeWidth={2.5}/>}
                </button>
              </div>
              {isFocused && (
                <Card
                  className="absolute top-full mt-[6px] w-full py-2 px-3 rounded-xl z-50"
                >
                  <PasswordChecklist
                    className="text-sm font-normal"
                    rules={["minLength", "specialChar", "number", "capital"]}
                    minLength={8}
                    value={formData.password}
                    iconSize={11}
                    invalidColor="#71717a"
                    invalidTextColor="#71717a"
                    onChange={(isValid) => {setisPassValid(isValid)}}
                  />
                </Card>
              )}
            </div>
          </div>
          
        </div>
        <CardFooter className="flex justify-center w-full flex-col p-0 mt-5">
          <Button variant="default" className="w-full " onClick={handleSignUp} disabled={disabled}>
            Sign Up
          </Button>
          <p className="text-[14px] font-normal mt-2 text-[#09090B]">
            Already have an account?{" "}
            <Link to="/" className="text-black font-semibold underline underline-offset-2">
              Sign In
            </Link>
          </p>
        </CardFooter>
      </form>
    </AuthWrapper>
  );
};

export default SignUp;
