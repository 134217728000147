import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardFooter } from '@/components/shadcn-ui/card'
import { Label } from '@/components/shadcn-ui/label'
import { Input } from '@/components/shadcn-ui/input'
import { Button } from '@/components/shadcn-ui/button'
import resetIcon from '../images/SVG/resrtPass.svg'
import { resetPassword } from 'aws-amplify/auth'
import { toast } from 'react-toastify'
import { AuthWrapper } from '@/components/ui/CardWrapper';

const ForgotPass = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    if (!email) {
      toast.error('Please enter your email.');
      return;
    }
    setLoading(true);
    try {
      const output = await resetPassword({ username: email });
      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        toast.success('Verification code sent to your email.');
        navigate('/verify-password', { state: { email } });
      } else if (output.nextStep.resetPasswordStep === 'DONE') {
        console.log('Password reset successfully');
      }
    } catch (err) {
      switch(err.message) {
        case 'Username/client id combination not found.':
          toast.error('Email not found. Please enter a valid email.');
          break;
        default:
          toast.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper icon={resetIcon} heading='Reset Password' forgetPass>
        <div className="flex flex-col space-y-1.5">
          <Label htmlFor="email" className="font-semibold text-left mt-5">Email</Label>
          <Input
            className="text-[#868C98] font-normal "
            id="email"
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
      <CardFooter className="flex justify-center w-full flex-col p-0 mt-5">
        <Button
          variant="default"
          className="w-full "
          onClick={handleResetPassword}
          disabled={loading}
        >
          Reset Password
        </Button>
        <p className='text-[14px] font-normal mt-2 text-[#09090B]'>Changed your mind? <span onClick={() => navigate(-1)} className='text-black font-semibold underline cursor-pointer underline-offset-2'>Go Back</span></p>
      </CardFooter>
    </AuthWrapper>
  );
};

export default ForgotPass;