import { configureStore, combineReducers } from '@reduxjs/toolkit';
import reportsReducer from './ReportsSlice';
import userReducer from './UserSlice';
import toolsReducer from './ToolsSlice';
import profileReducer from './ProfileSlice';

const appReducer = combineReducers({
  reportsSlice: reportsReducer,
  userSlice: userReducer,
  toolsSlice: toolsReducer,
  profileSlice: profileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer
});