import { useEffect, useRef } from "react";

function TextInput({ name, label, required, placeholder, type = "text", value, onChange, textArea = false, className, preIcon, postIcon, width, useWidthOnMobile = true, searchClicked}) {

  const inputref = useRef(null);

  useEffect(() => {
    if (searchClicked) {
      inputref.current.focus();
    }
  }, [searchClicked]);

  if (textArea) {
    return (
      <div className=" text-sm">
        <label className="font-semibold text-[14px]">{label}</label>
        <textarea
          ref={inputref}
          rows={3}
          required={required}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="rounded-[10px]  resize-none md:py-2.5 py-1.5 md:px-4 px-3 placeholder:text-xs md:placeholder:text-sm text-xs md:text-sm h-28 text-[14px] font-SuisseIntlLight font-normal text-[#868C98] border-[1px] border-[#E2E4E9] hover:border-[#CED0D5] focus:border-[#A6A8AD] focus:ring-0 focus:outline-none w-full"
        />
      </div>
    );
  }
  return (
    <div className={`flex flex-col gap-[2px] ${className} text-sm ${label ? "" : "h-9 md:h-[42px]"}`}>
      {label && (
        <label className="font-semibold font-SuisseIntlLight text-[14px]">{label}</label>
      )}
      <input
        ref={inputref}
        required={required}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`rounded-[10px] px-[13px] py-[11px] inputFieldShadow text-[14px] font-SuisseIntlLight font-normal text-[#525866] placeholder:text-[#868C98] border-[1px] md:py-2.5 md:px-4 placeholder:text-xs md:placeholder:text-sm text-xs md:text-sm ${preIcon ? "pl-9" : ""} ${postIcon ? "pr-9" : ""} border-[#E2E4E9] hover:border-[#CED0D5] focus:border-[#A6A8AD] focus:ring-0 focus:outline-none ${width ? `${useWidthOnMobile ? "" : "sm:"}w-[${width}]` : "w-full"}`}
      />
      {preIcon && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {preIcon}
        </div>
      )}
      {postIcon && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          {postIcon}
        </div>
      )}
    </div>
  );
}

export default TextInput;
