import React, { useState } from 'react'
import { Label } from '@/components/shadcn-ui/label'
import { Input } from '@/components/shadcn-ui/input'
import { Button } from '@/components/shadcn-ui/button'
import VerifyPassword from '../images/SVG/verifyPass.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth'
import { AuthWrapper } from '@/components/ui/CardWrapper'

const VerifyPass = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email || '';
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleConfirmResetPassword = async () => {
    setLoading(true);
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword,
      });
      toast.success('Password reset successfully.');
      navigate('/');
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      const output = await resetPassword({ username: email });
      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE')
        toast.success('Verification code sent to your email.');
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <AuthWrapper icon={VerifyPassword} heading="Enter Verification Code">
      <form className="text-left w-full" onSubmit={(e) => e.preventDefault()}>
        <div className="grid w-full items-center gap-4 text-left mt-5">
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="verify-code" className="font-semibold">
              Verification Code
            </Label>
            <Input
              required
              id="verify-code"
              placeholder="Please enter your verification code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="text-[#868C98] font-normal"
            />
          </div>
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="password" className="font-semibold">
              New Password
            </Label>
            <Input
              required
              id="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Please enter your new password"
              type="password"
              autoComplete="new-password"
              className="text-[#868C98] font-normal"
            />
          </div>
          <Label
            className="font-semibold underline cursor-pointer underline-offset-2"
            onClick={!loading && handleResendCode}
          >
            Resend Code
          </Label>
          <div className="flex flex-col gap-2 items-center">
            <Button
              className="font-medium w-full"
              variant="default"
              onClick={handleConfirmResetPassword}
              disabled={loading}
            >
              Confirm New Password
            </Button>
            <p className="text-sm font-normal text-[#09090B]">
              Changed your mind?{' '}
              <span
                onClick={() => navigate(-1)}
                className="text-black font-semibold underline cursor-pointer underline-offset-2"
              >
                Go Back
              </span>
            </p>
          </div>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default VerifyPass;