import { createSlice } from '@reduxjs/toolkit'
import { FetchToolFields, FetchToolInputs, FetchToolOutputs } from './ToolsApis'

const initialState = {
    loading: false,
    toolsLoader: false,
    toolFields: [],
    toolInputs: [],
    toolOutputs: [],
    selectedTool: null,
}

const toolsSlice = createSlice({
    name: 'toolsSlice',
    initialState,
    reducers: {
        setSelectedTool: (state, action) => {
            state.selectedTool = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(FetchToolFields.pending, (state) => {
            state.toolsLoader = true
        })
        builder.addCase(FetchToolFields.fulfilled, (state, action) => {
            state.toolsLoader = false
            state.toolFields = action.payload?.tools
        })
        builder.addCase(FetchToolFields.rejected, (state) => {
            state.toolsLoader = false
        })
        builder.addCase(FetchToolInputs.pending, (state) => {
            state.loading = true
        })
        builder.addCase(FetchToolInputs.fulfilled, (state, action) => {
            state.loading = false
            state.toolInputs = action.payload?.tool_inputs
        })
        builder.addCase(FetchToolInputs.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(FetchToolOutputs.pending, (state) => {
            state.loading = true
        })
        builder.addCase(FetchToolOutputs.fulfilled, (state, action) => {
            state.loading = false
            state.toolOutputs = action.payload?.tool_outputs
        })
        builder.addCase(FetchToolOutputs.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { setToolFields, setSelectedTool, setLoading } = toolsSlice.actions;

export const selectLoading = state => state.toolsSlice.loading || state.toolsSlice.toolsLoader;
export const selectToolFields = state => state?.toolsSlice?.toolFields;
export const selectSelectedTool = state => state?.toolsSlice?.selectedTool;
export const selectToolInputs = state => state?.toolsSlice?.toolInputs;
export const selectToolOutputs = state => state?.toolsSlice?.toolOutputs;

export default toolsSlice.reducer;


