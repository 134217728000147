import { format } from "date-fns";

export const formatTimestamp = (date, type = "default") => {
  if (!date) {
    return "";
  }

  const timestamp = new Date(date);

  switch (type) {
    case "m/d/y/h":
      // Format: June 11 2024, 11:47
      return format(timestamp, "MMM d yyyy, HH:mm");

    case "d/m/y/h":
      // Format: 10 Oct 2024, 18:56
      return format(timestamp, "d MMM yyyy, HH:mm");

    case "d/m/y":
      // Format: 15 Jan 2024
      return format(timestamp, "d MMM yyyy");

    case "d.m.y":
      // Format: 15.08.2023
      return format(timestamp, "dd.MM.yyyy");

    default:
      // Default format: Jun 11, 11:47
      return format(timestamp, "MMM d, HH:mm");
  }
};
