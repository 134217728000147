import React, { useState, useEffect } from 'react'
import Logo from "../images/logotype.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { NavMenuButton } from './ui/MenuButton'
import { handleLogout } from '@/utils/authSession'
import { useSelector } from 'react-redux'
import { getTokenCount, selectUserData } from '@/redux/UserSlice'
import { selectFileUploading } from '../redux/ReportsSlice'
import { useSubscription } from '../utils/SubscriptionContext'
import settingIcon from '../images/SVG/settingIcon.svg'
import { Dialog, DialogContent, DialogTrigger } from './shadcn-ui/dialog'
import AddToken from './AddToken'
import { DropdownMenu } from '@radix-ui/react-dropdown-menu'
import { DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from './shadcn-ui/dropdown-menu'
import { Button } from './shadcn-ui/button'
import AddTokensIcon from '../images/SVG/AddTokensIcon.svg'
import SettingsIcon from '../images/SVG/SettingsIcon.svg'
import LogOutIcons from '../images/SVG/LogOutIcon.svg'
import MenuIcon  from '../images/SVG/menuIcon.svg'
import CloseIcon from '../images/closeIcon.png'
import { LogOut } from 'lucide-react';
import { selectSelectedTool } from '@/redux/ToolsSlice'

const Header = ({ payingCustomer }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [menuClicked, setMenuClicked] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [addTokenOpen, setAddTokenOpen] = useState(false)
  const path = window.location.pathname 
  const uploading = useSelector(selectFileUploading)
  const handleNavigation = (route) => {
    if (subscriptionExpired) return;
    setMenuClicked(false)
    if (location.pathname.includes(route) && !uploading) {
      window.location.reload()
    }else{
      navigate(route)
    }
  }

  const hanldeLogoClick = (dest)=>{
    if (!uploading) {
      if (dest === 'home') {
        navigate('/')
      }
      else if (dest === 'tool') {
        navigate(`/${location.pathname.split('/')[1]}`)
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
        setMenuClicked(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { daysLeft } = useSubscription();
  const isTrialEnded = daysLeft < 0;
  const subscriptionExpired = (isTrialEnded && !payingCustomer)

  const getVariant = ({ btnPath, uploading }) => {
    if (uploading && (btnPath === path)) {
      return 'selected-disabled'
    } else if (btnPath === path) {
      return 'selected'
    } else if (uploading || subscriptionExpired) {
      return 'disabled'
    } else {
      return 'default'
    }
  }
 
  const tokens = useSelector(getTokenCount)
  const user = useSelector(selectUserData)
  const selectedTool = useSelector(selectSelectedTool)
  const isHeader = (path === '/' || path === '/settings')

  return (
    <div className={`relative`}>
      <div className={`relative z-0 mt-5 sm:mt-[20px] mb-[4px] h-[60px] px-5 py-4 w-[90vw] md:w-[80vw] bg-white border border-[#E4E4E7] header-shadow flex justify-between gap-[4px] items-center md:rounded-[20px] sm: ${menuClicked ? 'rounded-t-[20px] rounded-b-[4px] z-20' : 'rounded-[20px]'}`}>
        <div className={`flex items-center gap-3 ${uploading ? "cursor-not-allowed" : "cursor-pointer"}`}>
          <img className='w-[104px] h-6 object-contain' src={Logo} alt="logo not found" onClick={()=>{hanldeLogoClick('home')}}/>
          <p className='text-black font-SuisseIntlLight font-normal w-[122px] h-6 md:text-base text-sm mt-2 md:mt-[2px] md:inline' onClick={()=>{hanldeLogoClick('tool')}}>{ isHeader ? 'Medical AI Suite' : selectedTool}</p>
        </div>
        <div className='flex gap-6 items-center'>
          {(tokens || tokens === 0) && <h2 className='md:flex hidden text-[16px] font-bold font-SuisseIntlLight tracking-wider min-w-max'>{tokens?.toLocaleString('en')} Tokens</h2>}
          {user?.is_admin && 
          <Dialog open={addTokenOpen} onOpenChange={setAddTokenOpen}>
            <DialogTrigger>
              <Button variant='outline' className='hidden md:flex text-[#09090B]'>Add Tokens</Button>
            </DialogTrigger>
            <DialogContent className="rounded-3xl">
              <AddToken setIsOpen={setAddTokenOpen}/>
            </DialogContent>
          </Dialog>
          }
          <Link to='/settings' className='md:flex hidden -mr-[1px] min-w-max'>
          <img src={settingIcon} alt="Settings Icon" />
          </Link>
          <LogOut onClick={handleLogout} size={20} className='text-gray-600 -ml-[1px] cursor-pointer hidden md:flex min-w-max'/>
        </div>
        <div className='flex md:hidden'>
        <DropdownMenu open={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <DropdownMenuTrigger asChild>
        <Button className='p-0 flex-none focus:border-none hover:bg-transparent cursor-pointer' variant="ghost">
        <img src={isMenuOpen ? CloseIcon : MenuIcon} alt='Menu Icon' className='w-[14px] h-[14px] object-contain'/>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[290px] fixed -right-[28px] top-3 rounded-[14px]">
        <DropdownMenuLabel>Menu</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <Dialog open={addTokenOpen} onOpenChange={(open) => { 
                if (open) setIsMenuOpen(false);
                setAddTokenOpen(open);
            }}>
              <DialogTrigger className='flex items-center justify-between w-full'>
                <div className='flex gap-2 items-center'>
            <img src={AddTokensIcon} alt="" />
            <span className='font-SuisseIntlLight font-semibold'>Add Tokens</span>
                </div>
            <DropdownMenuShortcut className='tracking-wider'>{tokens?.toLocaleString('en')} Tokens</DropdownMenuShortcut>
              </DialogTrigger>
            <DialogContent className="rounded-3xl" addingReport>
              <AddToken setIsOpen={setAddTokenOpen}/>
            </DialogContent>
          </Dialog>
          </DropdownMenuItem>
          <DropdownMenuItem className='cursor-pointer' onClick={()=> navigate('/settings')}>
            <img src={SettingsIcon} alt="" />
            <span className='font-SuisseIntlLight font-semibold'>Settings</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout} className='cursor-pointer'>
          <img src={LogOutIcons} alt="" />
          <span className='font-SuisseIntlLight font-semibold text-red-500'>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>

        </div>
      
      </div>
      <div className='hidden'>
        {menuClicked && <div className="fixed top-0 left-0 w-full h-full bg-gray-700 opacity-30 z-10 md:hidden" onClick={() => setMenuClicked(false)}></div>}
        <div className={`${menuClicked ? 'opacity-100' : 'opacity-0 hidden'} flex flex-col gap-[4px] p-[8px] md:hidden border absolute mt-[-15px] left-1/2 transform -translate-x-1/2 rounded-b-[20px] bg-white border-gray-200 nav-shadow w-[90vw] z-20`}>
          <NavMenuButton className={`rounded-[4px] text-black ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} variant={uploading ? 'disabled' : getVariant({ btnPath: '/add-report' })} subname='+' name=' Add report' onClick={() => { handleNavigation('add-report') }} />
          <NavMenuButton className={`rounded-[4px] text-black ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} variant={uploading ? 'disabled' : getVariant({ btnPath: '/scribe' })} name='Reports' onClick={() => { handleNavigation('scribe') }} />
          <NavMenuButton className='rounded-t-[4px] rounded-b-[12px] text-[#525866]' variant={uploading ? 'disabled' : ''} last={true} name='Logout' onClick={handleLogout} />
        </div>
      </div>

      {/* isTrialEnded && <TrialEndModal/> */}
    </div>
  )
}

export default Header
