import React from 'react'
import { GradientBorder } from './GradientBorder'
import DarkLogo from '../../images/logotype.png'
import { Card, CardDescription, CardHeader, CardTitle } from '../shadcn-ui/card'

export function CardWrapper({ children, dashedBorder, className }) {
  return (
    <div className={` ${className}  ${dashedBorder ? 'border border-dashed cursor-pointer':'border'} w-full flex flex-col  items-center text-center`}>
      {children}
    </div>
  )
}

export function MainCardWrapper ({children, addingReport, className}){
  return(
    <div className={`${addingReport ? "w-full" : "mt-4 p-[12px] sm:p-[24px] w-[90vw] md:w-[80vw] card-shadow"} ${className}`}>
      {children}
    </div>
  )
}

export function NoPaddingCardWrapper ({children, isDrawer, edit, className}){
  return(
    <div className={`${isDrawer || edit ? 'mt-0 max-w-[100%] w-full': 'mt-4 card-shadow max-w-full w-[90vw] md:w-[80vw]'} ${className}`}>
      {children}
    </div>
  )
}
export function NoShadowCardWrapper ({children, isDrawer= false}){
  return (
    <div className={`${isDrawer ? 'mt-0': 'mt-4'} max-w-[764px] w-[90vw] md:w-[80vw] lg:w-[60vw]`}>
      {children}
    </div>
  )
}


export function AuthWrapper ({children, className, heading, icon, forgetPass= false}){
  return(
    <div className='w-full bg-[#FAFAFA] flex flex-col gap-2 md:gap-[70px] items-center sm:flex sm:justify-center font-SuisseIntl'>
      <div className='flex gap-3 items-center mt-12'>
        <img className='w-[104px] object-contain' src={DarkLogo} alt='logo not found' />
        <p className='text-black font-SuisseIntlLight font-medium text-[14px] md:text-[16px] mt-[3px]'>Medical AI Suite</p>
      </div>
      <div className='w-[90vw] md:w-[440px] max-w-[440px] rounded-2xl px-0 bg-transparent md:bg-white p-5 md:shadow-sm md:p-[30px] mt-0 md:mt-10 text-center transition-all ease-in-out duration-150 flex flex-col justify-center items-center '>
        <GradientBorder>
          <div className="shadow-sm bg-white rounded-full px-4 py-4">
            <img width={'24px'} height={'24px'} src={icon} alt='Login circle not found' />
          </div>
        </GradientBorder>
        <h4 className='mt-[20px] md:text-[24px] text-xl font-semibold font-SuisseIntlLight'>{heading}</h4>
        <div className='w-full'>
        {children}
        </div>
      </div>
    </div>
  )}

  export const ProfileWrapper = ({ children, className, title, description, hasButton = false, buttonContent = null }) => {
    return (
      <Card className={`border-none shadow-none w-full h-full md:bg-white bg-transparent ${className}`}>
        <CardHeader
          className="pt-0 px-0 pb-4 flex flex-col sm:flex-row justify-between items-center"
        >
          <div className='w-full align-left'>
            <CardTitle className="text-xl">{title}</CardTitle>
            {description && (
              <CardDescription className="font-normal mt-2 text-[14px] text-[#71717a]">
                {description}
              </CardDescription>
            )}
          </div>
          {hasButton && buttonContent}
        </CardHeader>
        <>{children}</>
      </Card>
    );
  };  

export const DialogWrapper = ({ children, title, description, icon }) => {
  return (
    <div className='p-4 w-full flex flex-col'>
      <div className='flex justify-center items-center flex-col text-center mb-3'>
        <GradientBorder>
          <div className="shadow-sm bg-white rounded-full px-4 py-4">
            <img width={'24px'} src={icon} alt='icon' />
          </div>
        </GradientBorder>
        <h1 className='font-semibold font-SuisseIntlLight mt-[16px] text-[20px]'>{title}</h1>
        <p className='mt-[4px] text-[#505050] md:text-[16px] text-[14px] font-SuisseIntlLight font-normal'>{description}</p>
      </div>
      {children}
    </div>
  )
}